export enum AvailableImageWidth {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export type PictureSizes = { screen: number, width?: AvailableImageWidth }[]

export type PictureSource = {
  srcset: string
  webp?: boolean
  media?: string
}
